//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "i-copyright",
  data: function data() {
    return {
      copyright: "Copyright 2024 上海邦邦送信息咨询服务有限公司 | 沪ICP备2024086631号-1"
    };
  },
  methods: {
    trunTag: function trunTag() {
      window.open("https://beian.miit.gov.cn/", "_blank");
    }
  }
};