var pre = '/settings/';
export default {
  path: '/settings',
  title: '系统设置',
  header: 'home',
  icon: 'md-options',
  children: [// {
  //     path: `${pre}department`,
  //     title: '部门设置'
  // },
  {
    path: "".concat(pre, "user"),
    title: '用户管理',
    icon: 'md-contacts'
  } // {
  //     path: `${pre}companies`,
  //     title: '企业管理'
  // }
  ]
};