import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "E:/JH/special-car/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
import { mapState, mapMutations } from 'vuex';
export default {
  name: 'iHeaderCollapse',
  computed: _objectSpread({}, mapState('admin/layout', ['isMobile', 'isTablet', 'isDesktop', 'menuCollapse', 'showReload'])),
  methods: _objectSpread(_objectSpread({}, mapMutations('admin/layout', ['updateMenuCollapse'])), {}, {
    // 展开/收起侧边栏
    handleToggleMenuSide: function handleToggleMenuSide(state) {
      if (this.isMobile) {
        this.updateMenuCollapse(false);
        this.$emit('on-toggle-drawer', state);
      } else {
        this.updateMenuCollapse(!this.menuCollapse);
      }
    }
  }),
  watch: {
    // 切换页面时，在移动端自动收起侧边栏
    // 强行传参 false 是因为有的路由不是在菜单栏发生的，toggle 会使其显示
    '$route': function $route() {
      if (this.isMobile) this.handleToggleMenuSide(false);
    },
    // 在平板时自动收起菜单
    isTablet: function isTablet(state) {
      if (!this.isMobile && state) this.updateMenuCollapse(true);
    },
    // 在桌面时自动展开菜单
    isDesktop: function isDesktop(state) {
      if (!this.isMobile && state) this.updateMenuCollapse(false);
    }
  }
};