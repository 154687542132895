import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "E:/JH/special-car/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
import Languages from '@/i18n/locale';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'iHeaderI18n',
  data: function data() {
    return {
      languages: Languages
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState('admin/i18n', ['locale'])), mapState('admin/layout', ['isMobile'])),
  methods: _objectSpread(_objectSpread({}, mapActions('admin/i18n', ['setLocale'])), {}, {
    handleClick: function handleClick(locale) {
      if (locale === this.locale) return;
      this.setLocale({
        locale: locale,
        vm: this
      });
    }
  })
};