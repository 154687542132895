var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c(
      "div",
      { staticClass: "footer-a" },
      [
        _c("GlobalFooter", {
          staticClass: "i-copyright",
          staticStyle: { padding: "0px" },
          attrs: { copyright: _vm.copyright },
          nativeOn: {
            click: function($event) {
              return _vm.trunTag.apply(null, arguments)
            }
          }
        })
      ],
      1
    ),
    _vm._v("\n   \n  "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer-b" }, [
      _c("a", {
        staticStyle: {
          display: "inline-block",
          "text-decoration": "none",
          height: "20px",
          "line-height": "20px"
        },
        attrs: {
          target: "_blank",
          href:
            "https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=2024086631"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }