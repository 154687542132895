var pre = '/business/';
export default [{
  path: '/business/client',
  title: '家长孩子信息管理',
  header: 'home',
  icon: 'ios-people',
  children: [{
    path: "".concat(pre, "client/parent"),
    title: '家长管理',
    icon: 'ios-man'
  }, {
    path: "".concat(pre, "client/child"),
    title: '孩子管理',
    icon: 'md-ionitron'
  }]
}, {
  path: '/business/employee',
  title: '司机车辆信息管理',
  header: 'home',
  icon: 'md-speedometer',
  children: [{
    path: "".concat(pre, "employee/driver"),
    title: '司机管理',
    icon: 'ios-contact'
  } // {
  //   path: `${pre}employee/vehicle`,
  //   title: '车辆管理',
  //   icon: 'ios-car',
  // }
  ]
}, {
  path: '/business/circuit',
  title: '线路信息管理',
  header: 'home',
  icon: 'md-shuffle'
}, {
  path: '/business/trip',
  title: '行程管理',
  header: 'home',
  icon: 'ios-barcode-outline'
}, {
  path: '/business/finance',
  title: '财务管理',
  header: 'home',
  icon: 'ios-stats-outline',
  children: [{
    path: "".concat(pre, "finance/customerbill"),
    title: '客户账单',
    icon: 'ios-people-outline'
  }, {
    path: "".concat(pre, "finance/driverbill"),
    title: '司机账单',
    icon: 'ios-car'
  }]
}, {
  path: '/business/city',
  title: '城市配置',
  header: 'home',
  icon: 'md-globe'
}];