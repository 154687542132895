import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "E:/JH/special-car/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'business-';
export default [{
  path: '/business/client',
  name: 'businessclient',
  redirect: {
    name: "".concat(pre, "parent")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'parent',
    name: "".concat(pre, "parent"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '家长管理',
      closable: true
    }),
    component: function component() {
      return import('@/pages/business/parentchild/parent');
    }
  }, {
    path: 'child',
    name: "".concat(pre, "child"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '孩子管理',
      closable: true
    }),
    component: function component() {
      return import('@/pages/business/parentchild/child');
    }
  }]
}, {
  path: '/business/employee',
  name: 'businessemployee',
  redirect: {
    name: "".concat(pre, "driver")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'driver',
    name: "".concat(pre, "driver"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '司机管理',
      closable: true
    }),
    component: function component() {
      return import('@/pages/business/drivervehicle/driver');
    }
  } // {
  //   path: 'vehicle',
  //   name: `${pre}vehicle`,
  //   meta: {
  //     ...meta,
  //     title: '车辆管理',
  //     closable: true
  //   },
  //   component: () => import('@/pages/business/drivervehicle/vehicle')
  // }
  ]
}, {
  path: '/business/',
  name: 'businesscircuit',
  redirect: {
    name: "".concat(pre, "circuit")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'circuit',
    name: "".concat(pre, "circuit"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '线路信息管理',
      closable: true
    }),
    component: function component() {
      return import('@/pages/business/circuit');
    }
  }]
}, {
  path: '/business/',
  name: 'businesstrip',
  redirect: {
    name: "".concat(pre, "trip")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'trip',
    name: "".concat(pre, "trip"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '行程管理',
      closable: true
    }),
    component: function component() {
      return import('@/pages/business/trip');
    }
  }]
}, {
  path: '/business/finance',
  name: 'businessemployee',
  redirect: {
    name: "".concat(pre, "customerbill")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'customerbill',
    name: "".concat(pre, "customerbill"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '客户账单',
      closable: true
    }),
    component: function component() {
      return import('@/pages/business/finance/customerbill');
    }
  }, {
    path: 'driverbill',
    name: "".concat(pre, "driverbill"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '司机账单',
      closable: true
    }),
    component: function component() {
      return import('@/pages/business/finance/driverbill');
    }
  }]
}, {
  path: '/business/',
  name: 'city',
  redirect: {
    name: "".concat(pre, "city")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'city',
    name: "".concat(pre, "city"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '城市配置',
      closable: true
    }),
    component: function component() {
      return import('@/pages/business/city');
    }
  }]
}];