import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "E:/JH/special-car/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import iMenuSideItem from "./menu-item";
import iMenuSideSubmenu from "./submenu";
import iMenuSideCollapse from "./menu-collapse";
import tTitle from "../mixins/translate-title";
import { mapState, mapGetters } from 'vuex'; // 元素是否在可视区域

function isElementInViewport(el) {
  var rect = el.getBoundingClientRect();
  return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
}

export default {
  name: 'iMenuSide',
  mixins: [tTitle],
  components: {
    iMenuSideItem: iMenuSideItem,
    iMenuSideSubmenu: iMenuSideSubmenu,
    iMenuSideCollapse: iMenuSideCollapse
  },
  props: {
    hideLogo: {
      type: Boolean,
      default: false
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('admin/layout', ['siderTheme', 'menuAccordion', 'menuCollapse'])), mapState('admin/menu', ['activePath', 'openNames'])), mapGetters('admin/menu', ['filterSider'])),
  watch: {
    '$route': {
      handler: function handler() {
        this.handleUpdateMenuState();
      },
      immediate: true
    },
    // 在展开/收起侧边菜单栏时，更新一次 menu 的状态
    menuCollapse: function menuCollapse() {
      this.handleUpdateMenuState();
    }
  },
  methods: {
    handleUpdateMenuState: function handleUpdateMenuState() {
      var _this = this;

      this.$nextTick(function () {
        if (_this.$refs.menu) {
          _this.$refs.menu.updateActiveName();

          if (_this.menuAccordion) _this.$refs.menu.updateOpened(); // 聚焦当前项

          _this.$nextTick(function () {
            var $activeMenu = document.getElementsByClassName('ivu-menu-item ivu-menu-item-active ivu-menu-item-selected');

            if ($activeMenu && $activeMenu.length && !isElementInViewport($activeMenu[0])) {
              var activeMenuTop = $activeMenu[0].getBoundingClientRect().top;
              var $menu = _this.$refs.menu.$el;
              setTimeout(function () {
                _this.$ScrollTop($menu, {
                  to: activeMenuTop,
                  time: 0
                });
              }, 300);
            }
          });
        }
      });
    }
  }
};